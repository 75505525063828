import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// import {  ThemeProvider, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, Container, Paper, Box, Chip, FormControlLabel, Switch, FormControl, InputLabel, Select, MenuItem, TextField, MuiThemeProvider, Link } from '@material-ui/core';
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Avatar from './Avatar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import DbConnection from './DbConnection';
// import { theme } from './Constants/CustomTheme'
// import ValidateAuthentication from './ValidateAuthentication';

const db = new DbConnection();


const useStyles = {
    root: {
        flexGrow: 1,
    },
    formControl: {
        margin: 1,
        minWidth: 120,
    },

    menuButton: {
        marginRight: 2,
    },
    title: {
        flexGrow: 1,
    },
    container: {
        paddingTop: 5,
    },
    paperBlock: {
        padding: 10,
    },
   
    btn: {
        borderRadius: 0,
        margin: 5,
        '&:hover': {
            background: '#fdc800',
            color: '#002147',
            borderColor: '#fdc800',
        },
    }
};

class TextSentenceSign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wdblist: [],
            langcol: 'engw',
            wordlist: [],
            statusMsg: 'Idle',
            cIndex: 0,
            maxIndex: 0,
            currentWord: "",
            customInput: "",
            islwords: ["idle"],
            lang: 'e',
       

        }
    }
    componentDidMount() {
        this.hiddenTextEvent();
        var lang = 'e';
        this.setState({
            lang:lang,
        });
        this.setLanguage(lang);
        // this.setLang();


    }

    load = async (params) => {
            this.setState({
                langcol: 'engw',
            });
      

        var result2 = await db.GetWordList(params);
        if (result2 === '') {
            console('exit');
            return;
        }
        else {
            this.setState({
                wdblist: result2,
            });
        }
    }

   


    getISL = async (lang) => {
        this.setState({
            lang: lang
        });

        // var sentence = this.state.menu1 + " " + this.state.menu2 + " " + this.state.menu3;

        var result = await db.GetTextSentenceSign(lang, this.state.customInput);
        if (result === '') {
            console('exit');
            return;
        }

        console.log(JSON.stringify(result));
        this.setState({
            data: result,
            wordlist: result['data'],
            islwords: result['PoSTag'],
        });

        this.get(result);

    }

    get = (result) => {
        const data = result;
        var txt = document.getElementsByClassName('txtaSiGMLText');
        txt[0].innerHTML = data.SiGML;

        var btn = document.getElementsByClassName('bttnPlaySiGMLText');
        btn[0].click();

        this.setState({
            statusMsg: "Playing",
        });

    }

    nextSign = () => {
        const data = this.state.data;
        if (this.state.cIndex <= this.state.maxIndex) {
            const cur = data[this.state.cIndex];
            this.setState({
                currentWord: data[this.state.cIndex].word,
            })
            var btn = document.getElementsByClassName('bttnPlaySiGMLText');
            var txt = document.getElementsByClassName('txtaSiGMLText');
            if (data.length > 0) {
                txt[0].innerHTML = cur.SiGML;
            }
            btn[0].click();
        }
    }
    setLanguage= (e) => {
        this.setState({
            //langv: !this.state.langv,
            lang: e,
            customInput: "",
          
        });
        this.load(e);
    }
   

    hiddenTextEvent = () => {
        var el = document.getElementById('gloss');
        const self = this;
        el.addEventListener("change", function () {
            self.setState({
                statusMsg: el.value,
            })
        });
    }



    render() {

        
        return (
            // <MuiThemeProvider theme={theme}>
                <Box height="100vh" flex={1} display="flex" flexDirection="column">
                    {/* <Grid>
                        <Navbar />
                    </Grid> */}
                                        <Typography variant='h4' display='block' style={{backgroundColor:'#030303', padding:15}}  color={'secondary'}>Map Direcitons in Indian Sign Language </Typography>
                    <Grid container spacing={2}>
                        <Grid item md={8} >
                            <Card >
                                <CardActions >
                                   


                                    <div style={{ width: "100%", textAlign: "center", margin: 10, padding: 10 }}>
                                    Click here to <Link href='https://goo.gl/maps/kDfwYDsmUggSDMyL6' target={'_blank'}>Open Google Map</Link>. <br/>
                                    Set source and destination route and copy particular direction statement and paste in the following Input field.

                                        {/* <Grid container spacing={3} style={{ margin: 10 }}>

                                        
                                            <Grid item sm={5} style={{ textAlign: 'left' }}>
                                                <Button variant={(this.state.lang == 'e') ? "contained" : "outlined"} color="secondary" onClick={() => { this.setLanguage('e') }}>English</Button>
                                            </Grid>

                                        </Grid> */}
                                        {/* <Grid component="label" container alignItems="center" spacing={1}>
                                            <Grid item><Typography variant="h6">Language: </Typography></Grid>
                                            <Grid>English</Grid>
                                            <Grid item>
                                                <Switch size="small" onChange={() => { this.changeLanguage() }} />
                                            </Grid>
                                            <Grid item>Hindi</Grid>
                                        </Grid> */}
                                        {/* <Speech /> */}
                                        {/* <Backdrop className={classes.backdrop} open={this.state.openCircleLoading} onClick={this.handleClose}>
                                            <CircularProgress color="secondary" />
                                        </Backdrop> */}
                                    </div>
                                </CardActions>
                                <CardContent>
                                    <Grid>
                                        <Box padding={2}>
                                            <Typography variant="h5" style={{marginBottom:10}} >Type your own text to translate in ISL </Typography>

                                            <TextField
                                                variant="outlined"
                                                value={this.state.customInput}
                                                onChange={
                                                    (e) => {
                                                        this.setState({
                                                            customInput: e.target.value,
                                                        })
                                                    }
                                                }
                                                fullWidth
                                                placeholder="Head east on University College of Engineering Rd"
                                            />
                                        </Box>
                                        <Box  textAlign={"center"}>
                                            <Box display={"inline"} margin={5}>
                                        </Box>

                                           

                                        </Box>

                                       
                                    </Grid>
                                    <Grid >
                                        {/* data: {JSON.stringify(this.state.wordlist)}<br/> */}
                                        <ol>
                                            <>  {this.state.wordlist.map((row,index) => (
                                                row.map((sub_row,index) => (
                                                    <Typography key={index} style={{paddingRight:10}} display={'inline'}
                                                    >{sub_row['word']  }, </Typography>))
                                            ))}
                                            </>
                                        </ol>
                                    </Grid>

                                </CardContent>
                                <CardContent>
                                    <div>

                                        <input id="gloss" type="hidden"></input>


                                        <Button
                                            id="eweb"
                                            onClick={() => { this.getISL(this.state.lang) }}
                                            variant="contained"
                                            color="primary">
                                            Translate
                                        </Button>
                                    </div>
                                </CardContent>
                                <CardContent>
{/* 
                                    <>  {this.state.wdblist.map((row, index) => (

                                        <Button className={classes.btn} key={index} variant="outlined">{row[this.state.langcol]}</Button>
                                    ))}
                                    </> */}

                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={4}>
                            <Paper>

                                <Avatar />
                                <Grid>

                                    <Alert severity="success" variant="outlined" icon={false}>
                                        {/* <AlertTitle><strong>Current :</strong>  {this.state.statusMsg}</AlertTitle> */}
                                        <AlertTitle><strong>Words :</strong>  {this.state.wordlist.map((word,index) => (
                                                <Typography
                                                    key={index} display={'inline'}
                                                    style={{ fontWeight:800, color: (word[0]["id"] === this.state.statusMsg) ? "red" : "black" }}
                                                >{word[0]["text"]} </Typography>
                                                // <Typography>
                                                // {word[0]["text"]}</Typography>
                                            ))
                                            

                                        }</AlertTitle>
                                        <>  {this.state.islwords.map(word => (
                                            <Typography
                                                key={word['text']}
                                                display="inline"
                                                color={(word[0]['text'] === this.state.statusMsg) ? "secondary" : "primary"}
                                            >{word['text']} </Typography>
                                        ))}
                                        </>
                                    </Alert>
                                </Grid>

                            </Paper>
                        </Grid>
                    </Grid>


                </Box>
            // </MuiThemeProvider > 

        );
    }
}
export default (TextSentenceSign);