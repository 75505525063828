import logo from './logo.svg';
import './App.css';
import TextSentenceSign from './MyApp/TextSentenceSign';
import { Typography } from '@material-ui/core';
import { Component } from 'react';

class App extends Component {
  constructor(props) {
    super(props);
  }
  render(){

  return (
    <div className="App">
     <TextSentenceSign props ={this.props}/>
    </div>
  );
}

}

export default App;
